import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <div className="error-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="error-content">
                        <StaticImage
                            src="../images/404-error.jpg"
                            quality={95}
                            formats={[`AUTO`, `WEBP`, `AVIF`]}
                            alt="Ugni SAS Logo"
                        />
                        <h3>Oops! Página no encontrada</h3>
                        <p>Esa página ha cambiado su nombre o ha sido removida.</p>
                        <a href="index.html" className="default-btn-two">
              Ir a la página principal
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
