import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

// eslint-disable-next-line no-unused-vars
const Header = () => (
    <>
        <div className="navbar-area">
            <div className="mobile-nav">
                <a href="#" className="logo">
                    <StaticImage
                        src="../images/logo.png"
                        quality={95}
                        formats={[`AUTO`, `WEBP`, `AVIF`]}
                        alt="Ugni SAS Logo"
                    />
                </a>
            </div>
            <div className="main-nav nav-bar-three">
                <div className="container-fluid">
                    <nav className="container-max-2 navbar navbar-expand-md navbar-light ">
                        <a className="navbar-brand" href="/">
                            <StaticImage
                                src="../images/logo.png"
                                width={140}
                                quality={95}
                                formats={[`AUTO`, `WEBP`, `AVIF`]}
                                alt="Ugni SAS Logo"
                            />
                        </a>
                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto">
                                <li className="nav-item">
                                    <a href="/" className="nav-link active">
                                    Inicio
                                        <i className="bx bx-plus"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/about/" className="nav-link">
                                    ¿Qué hacemos?
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#experience" className="nav-link">
                                    Experiencia
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/services/" className="nav-link">
                                    Servicios
                                        <i className="bx bx-plus"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/blog/" className="nav-link">
                                    Blog
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/contact-us/" className="nav-link">
                                    Contacto
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="side-nav-responsive">
                <div className="container">
                    <div className="dot-menu">
                        <div className="circle-inner">
                            <div className="circle circle-one"></div>
                            <div className="circle circle-two"></div>
                            <div className="circle circle-three"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="side-nav-inner">
                            <div className="side-nav justify-content-center  align-items-center">
                                <div className="side-item">
                                    <div className="search-box">
                                        <i className="flaticon-loupe"></i>
                                    </div>
                                </div>
                                <div className="side-item">
                                    <div className="user-btn">
                                        <a href="#">
                                            <i className="flaticon-contact"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="side-item">
                                    <div className="nav-add-btn">
                                        <a href="/contact-us/" className="nav-menu-btn border-radius">
                                        Contacto
                                            <i className="bx bx-plus"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
