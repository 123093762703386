import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import Header from "./header"

import "@fontsource/livvic"
import "@fontsource/nunito"

import "../../dist/css/bootstrap.min.css"
import "../../dist/fonts/flaticon.css"
import "../../dist/css/boxicons.min.css"
import "../../dist/css/owl-theme-default.css"
import "../../dist/css/owl-carousel.css"
import "../../dist/css/magnific-popup.css"
import "../../dist/css/animate.min.css"
import "../../dist/css/meanmenu.css"
import "../../dist/css/responsive.css"
import "./layout.css"

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
            {children}
            <footer className="footer-area footer-bg2 pt-100">
                <div className="container">
                    <div className="footer-midal pb-70">
                        <div className="row">
                            <div className="col-lg-4 col-sm-7">
                                <div className="footer-widget">
                                    <div className="footer-img">
                                        <StaticImage
                                            src="../images/logo.png"
                                            width={120}
                                            quality={95}
                                            formats={[`AUTO`, `WEBP`, `AVIF`]}
                                            alt="Ugni SAS Logo"
                                        />
                                    </div>
                                    <p>
                                        Solucionamos problemas a través de la tecnología
                                    </p>
                                    <div className="footer-social-icon">
                                        <ul className="social-link">
                                            <li>
                                                <a href="https://medium.com/@ugni" rel="noreferrer" target="_blank"><i className="bx bxl-facebook" aria-label="Medium"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/ugni_io" rel="noreferrer" target="_blank"><i className="bx bxl-twitter" aria-label="Twitter"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/ugni.io/" rel="noreferrer" target="_blank"><i className="bx bxl-instagram" aria-label="Instagram"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://github.com/ugniio" rel="noreferrer" target="_blank"><i className="bx bxl-youtube" aria-label="Youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-5">
                                <div className="footer-widget">
                                    <h3>Recursos</h3>
                                    <ul className="footer-list">
                                        <li>
                                            <a href="page">Nuestro equipo</a>
                                        </li>
                                        <li>
                                            <a href="page">Servicios</a>
                                        </li>
                                        <li>
                                            <a href="page">Soluciones</a>
                                        </li>
                                        <li>
                                            <a href="page">eCommerce</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-7">
                                <div className="footer-widget pl-5">
                                    <h3>Compañía</h3>
                                    <ul className="footer-list">
                                        <li>
                                            <a href="/">Inicio</a>
                                        </li>
                                        <li>
                                            <a href="/about/">Sobre</a>
                                        </li>
                                        <li>
                                            <a href="/blog/">Blog</a>
                                        </li>
                                        <li>
                                            <a href="/contact-us/">Contacto</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-5">
                                <div className="footer-widget">
                                    <h3>Contacto</h3>
                                    <ul className="footer-list-two">
                                        <li>
                                            <i className="flaticon-telephone"></i>
                                            <a href="tel:+573012277864">+57 301 2277 864</a>
                                        </li>
                                        <li>
                                            <i className="flaticon-email-1"></i>
                                            <a
                                                href=""><span
                                                    className=""
                                                    data-cfemail="">info@ugni.io</span></a>
                                        </li>
                                        <li>
                                            <i className="flaticon-place"></i>
                                Para notificaciones y correspondencia: Cra 58C No. 142 20 Bogotá, Colombia
                                ¡Ahora el equipo es Full remoto!
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right-area">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="copy-right-text text-left">
                                    <p>
                                        Copyright 2021.&nbsp;
                                        <a href="/">Ugni SAS</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="copy-right-list">
                                    <ul>
                                        <li><a href="/privacy-policy/"> Política de privacidad & Términos </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <Helmet>
                <script src={withPrefix(`../../js/jquery-3.5.1.slim.min.js`)} type="text/javascript"></script>
                <script src={withPrefix(`../../js/wow.min.js`)} type="text/javascript"></script>
                <script src={withPrefix(`../../js/owl.carousel.min.js`)} type="text/javascript"></script>
                <script src={withPrefix(`../../js/jquery.magnific-popup.min.js`)} type="text/javascript"></script>
                <script src={withPrefix(`../../js/meanmenu.js`)} type="text/javascript"></script>
                <script src={withPrefix(`../../js/jquery.ajaxchimp.min.js`)} type="text/javascript"></script>
                <script src={withPrefix(`../../js/form-validator.min.js`)} type="text/javascript"></script>
                <script src={withPrefix(`../../js/custom.js`)} type="text/javascript"></script>
                <script src={withPrefix(`../../js/popper.min.js`)} type="text/javascript"></script>
                <script src={withPrefix(`../../js/bootstrap.min.js`)} type="text/javascript"></script>
            </Helmet>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
